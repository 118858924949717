<template>
  <div>
    <div class="card p-3 shadow-sm">
      <form @submit.prevent="">
        <div class="row justify-content-between">
          <div class="col-auto">
            <h5 class="e-text-red">
              General
            </h5>
          </div>
          <div class="col-auto" v-if="isAllowed('psc.main.general.edit') && isOwnVessel('pscReport')">
            <template v-if="toEdit">
              <button class="e-btn e-btn-outlined-red ml-2" type="button" @click="cancelUpdate" v-if="toEdit">Cancel</button>
              <button class="e-btn e-btn-blue ml-2" :class="hasChanges === false ? 'e-btn-blue-disabled' : ''" type="button" @click="updatePsc" :disabled="hasChanges === false">Save</button>
            </template>
            <template v-else>
              <button class="e-btn e-btn-green ml-2 px-3" type="button" @click="editMainReport">Edit General Details</button>
            </template>
          </div>
        </div>
        <table class="w-100">
          <tr>
            <td style="width: 25%">Owners Contract Type</td>

            <td>
              <input type="text" v-if="toEdit" v-model="mainReport.general.owners_contract_type" class="form-control-sm form-control"
                     name="owner-contract-type" id="owner-contract-type">
<!--              <span v-else> {{ pscGet('general.owners_contract_type')  | toUpper }}</span>-->
                  <span v-else> {{ pscGet('general.owners_contract_type') }}</span>
            </td>
          </tr>
          <tr>
            <td>Charterer <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td>
              <input type="text" v-if="toEdit" v-model="mainReport.general.charterer" class="form-control-sm form-control" name="charterer"
                     id="charterer">
<!--              <span v-else> {{ pscGet('general.charterer')  | toUpper }}</span>-->
              <span v-else> {{ pscGet('general.charterer') }}</span>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Operator <sub class="lead e-text-red font-weight-bold">*</sub></td>
            <td>
              <input type="text" v-if="toEdit" v-model="mainReport.general.operator" class="form-control-sm form-control" name="operator"
                     id="operator">
<!--              <span v-else> {{ pscGet('general.operator')  | toUpper }}</span>-->
              <span v-else> {{ pscGet('general.operator') }}</span>
            </td>
          </tr>
        </table>
        <hr/>
        <div class="row">
          <div class="col-12 text-right">
            <div class="mb-1" v-if="isAllowed('psc.main.general.view.crew.info') && isOwnVessel('pscReport')">
              <button class="e-btn e-btn-blue ml-2"
                      type="button"
                      :disabled="crewKeys.length > 0"
                      data-toggle="modal"
                      data-target="#crewListInformation">
                Crew List Information
              </button>
            </div>
            <div v-if="isAllowed('psc.main.general.view.crew.exp') && isOwnVessel('pscReport')">
              <button class="e-btn e-btn-blue ml-2"
                      @click.prevent="getCrewExperienceLists" :disabled="crewKeys.length > 0"
                      type="button"
                      data-toggle="modal">Crew Experience List
              </button>
            </div>
          </div>
        </div>
        <table class="w-100">
          <tr>
            <td style="width: 25%">Name of Master</td>
            <td>
              <p class="p-0 m-0" v-for="master in master">{{ master.last_name }}, {{ master.first_name }}
                {{ master.middle_name }}</p>
            </td>
          </tr>
          <tr>
            <td style="width: 25%">Name of C/E</td>
            <td>
              <p class="p-0 m-0" v-for="chiefEngineer in chiefEngineer">{{ chiefEngineer.last_name }}, {{ chiefEngineer.first_name }}
                {{ chiefEngineer.middle_name }}</p>
            </td>
          </tr>
          <tr>
            <td>Crew Numbers</td>
            <td>
              <p class="p-0 m-0" v-for="manningCount in manningCrewCount">{{ manningCount.name }} -
                <strong>{{ manningCount.count }}</strong></p>
            </td>
            <td>Total Crew Onboard:</td>
            <td><p class="p-0 m-0">{{ manningFromOnboard }}</p></td>
          </tr>
        </table>
        <div class="modal fade" id="crewListInformation" tabindex="-1" aria-labelledby="crewListInformationLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="crewListInformationLabel">Crew List Information as of {{ formattedEventDate }}</h5>
              </div>
              <div class="modal-body">
                <table class="table table-bordered table-responsive-lg">
                  <thead>
                  <tr>
                    <th class="align-middle text-center" scope="col" style="width: 7%;">RANK</th>
                    <th class="align-middle text-center" style="width: 15%" scope="col">NAME</th>
                    <th class="align-middle text-center" style="width: 7%" scope="col">IPN</th>
                    <th class="align-middle text-center" style="width: 4%" scope="col">EMBARK DATE</th>
                    <th class="align-middle text-center" style="width: 10%" scope="col">TIME ONBOARD AT TIME OF REPORT</th>
                    <th class="align-middle text-center" style="width: 25%" scope="col">MANNING</th>
                    <th class="align-middle text-center" style="width: 5%" scope="col">NATIONALITY</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(crew,index) in crewKeys">
                    <td class="align-middle text-center">{{ crew.rank.toUpperCase() }}</td>
                    <td class="align-middle" style="white-space: nowrap">
                      <router-link :to="{name:'CrewInfo',params:{id:crew.id}}" target="_blank"
                                   v-if="isAllowed('psc.main.general.view.crew.info-link')">
                        {{ crew.last_name }}, {{ crew.first_name }}
                        {{ crew.middle_name.substring(0, 1).toUpperCase() }}.
                      </router-link>
                      <span v-else>
                          {{ crew.last_name }}, {{ crew.first_name }} {{ crew.middle_name.substring(0, 1).toUpperCase() }}.
                        </span>
                    </td>
                    <td class="align-middle text-center">{{ crew.ipn.toUpperCase() }}</td>
                    <td class="align-middle text-center">{{ crew.embarkationDate }}</td>
                    <!--                      <td class="align-middle text-center">{{crew.disembarkationDate}}</td>-->
                    <td class="align-middle text-center">{{ crew.duration.toUpperCase() }}</td>
                    <td class="align-middle text-center" style="white-space: nowrap">{{ crew.manning.toUpperCase() }}</td>
                    <td class="align-middle text-center">{{ crew.nationality.toUpperCase() }}</td>
                  </tr>
                  </tbody>
                </table>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
        <div class="modal fade" id="crewExperienceInfo" tabindex="-1" aria-labelledby="crewExperienceInfoLabel" aria-hidden="true">
          <div class="modal-dialog modal-xl">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="crewListInformationLabel">Crew Experience List as of {{ formattedEventDate }}</h5>
                <!--              <button type="button" class="close" data-dismiss="modal" aria-label="Close">-->
                <!--                <span aria-hidden="true">&times;</span>-->
                <!--              </button>-->
              </div>
              <div class="modal-body" id="crewExperienceInfoBody" style="max-height:80vh; overflow-y: auto;">
                <template v-for="(crew,index) in crewLists">
                  <experience-ui :crew="crew"></experience-ui>
                </template>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import ObjectMixin from "../../../mixins/ObjectMixin";
import {AlertService} from "../../../services/AlertService";
import {DateService} from "../../../services/DateService";
import {KeyService} from "../../../services/KeyService";
import ExperienceUi from "../../crew/experience/ExperienceUi.vue";
import {permissionMixin} from "@/mixins/permissionMixins";
import {DataService} from "@/services/DataService";

export default {
  name: "PscGeneral",
  components: {ExperienceUi},
  data() {
    return {
      toEdit: false,
      mainReport: {},
      rankIdMaster: 1,
      rankIdCE: 2,
      crewLists: {},
    }
  },
  mounted() {
    $("#crewExperienceInfo").on('hide.bs.modal', function() {
      $("#crewExperienceInfoBody").scrollTop(0);
    });
  },
  mixins: [ObjectMixin, permissionMixin],
  methods: {
    ...mapActions(['updatePscInfo']),
    async updatePsc() {
      if (await AlertService.confirmUpdateAlert("Do you want to update ? ")) {
        const response = await this.updatePscInfo(this.mainReport);
        if (response) {
          AlertService.successAlert('PSC updated successfully', 'UPDATE PSC')
          this.resetFields()
        }
      }
    },
    editMainReport() {
      this.toEdit = true;
      this.mainReport = {
        id: this.pscGet('id'),
        general: {
          operator: this.pscGet('general.operator'),
          owners_contract_type: this.pscGet('general.owners_contract_type'),
          charterer: this.pscGet('general.charterer')
        }
      }
    },
    resetFields() {
      this.toEdit = false
      this.mainReport = {}
    },

    async getCrewExperienceLists() {
      const inspectionDate = this.pscGet('inspection_date')
      const vesselId = this.pscGet('vessel_id')
      const params = {
        onboard_from: inspectionDate,
        onboard_to: inspectionDate,
        vesselId: vesselId,
        hasImage: true,
      }
      swal.fire({
        'title': 'FETCHING CREW INFORMATION',
        allowEscapeKey: false,
        allowOutsideClick: false,
        allowEnterKey: false
      })
      swal.showLoading()
      const response = await KeyService.getCrewKeys(params)
      if (response) {
        $("#crewExperienceInfo").modal('show');
        this.crewLists = response.crews
      }
      swal.close();
    },

    async cancelUpdate() {
      if (this.hasChanges === false) {
        this.resetFields()
      } else {
        if (await AlertService.cancelAlert()) {
          this.resetFields()
        }
      }
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.hasChanges === true) {
      if (await AlertService.cancelChanges()) {
        next();
      } else {
        next(false);
      }

    } else {
      next();
    }
  },
  computed: {
    ...mapGetters([
      'pscReport',
      'crewKeys',
      'manningFromOnboard',
      'manningCrewCount'
    ]),

    master() {
      return Object.values(this.crewKeys).filter((crew) => crew.rank_id === this.rankIdMaster);
    },
    chiefEngineer() {
      return Object.values(this.crewKeys).filter((crew) => crew.rank_id === this.rankIdCE);
    },

    formattedEventDate() {
      return DateService.dateEliteFormat(this.pscGet('inspection_date'))
    },

    hasChanges() {
      if (this.toEdit === false) {
        return false;
      }

      let editedData = {
        owners_contract_type : this.mainReport.general.owners_contract_type === '' ? null : this.mainReport.general.owners_contract_type,
        charterer            : this.mainReport.general.charterer === '' ? null : this.mainReport.general.charterer,
        operator             : this.mainReport.general.operator === '' ? null : this.mainReport.general.operator,
      };

      return DataService.checkIfChanged(this.pscReport.general, editedData);
    }
  }
}
</script>

<style scoped>
table td {
  padding: 2px !important;
}
</style>
